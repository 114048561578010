<template>
  <tr>
    <template v-for="(cell, cellIndex) in row">
      <td
        v-if="!isVrm(cellIndex) && cellIsShort(cell)"
        class="has-text-grey-dark"
        :key="cellIndex"
      >
        {{ cell || '-' }}
      </td>
      <td
        v-else-if="!isVrm(cellIndex) && !cellIsShort(cell)"
        :key="cellIndex"
        @click="openCellModal(cell, cellIndex)"
      >
        <a class="has-text-grey-dark">{{ formatLongCell(cell) || '-' }}</a>
      </td>
      <td v-else :key="cellIndex">
        <button
          :class="{ 'is-loading': isLoading }"
          class="button is-small is-warning is-light is-fullwidth"
          :disabled="companionRequested"
          @click="goToCompanion(cell, mileage)"
        >
          {{ cell || '-' }}
        </button>
      </td>
    </template>
  </tr>
</template>

<script>
import * as companion from 'modules/companion/services'
export default {
  name: 'MultiSummaryRow',
  props: {
    row: {
      type: Array,
      default: () => []
    },
    tableHeaders: {
      type: Array,
      default: () => []
    },
    companionRequested: {
      type: Boolean,
      default: () => false
    },
    companionRequestedRow: {
      type: Number,
      default: () => null
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    isLoading() {
      return this.index === this.companionRequestedRow
    },
    mileage() {
      const index = this.tableHeaders.findIndex(
        header => header.label.toString().toLowerCase() === 'mileage'
      )

      return this.row[index]
    }
  },
  methods: {
    async goToCompanion(vrm, mileage) {
      try {
        this.$emit('companionRequested', true, this.index)
        await companion.value({ vrm, mileage })
      } finally {
        this.$emit('companionRequested', false)
      }
    },
    formatLongCell(cell) {
      return cell ? cell.slice(0, 74) + '...' : ''
    },
    openCellModal(cell, cellIndex) {
      this.$modal.open('multi/MultiCellFocus', {
        cell,
        header: this.tableHeaders[cellIndex]
      })
    },
    isVrm(index) {
      const label = this.tableHeaders[index]?.label ?? ''
      return label.toString().toLowerCase() === 'vrm'
    },
    cellIsShort(cell) {
      return cell && cell.toString().length < 75
    }
  }
}
</script>
